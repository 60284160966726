// Stimulus
import { Application } from '@hotwired/stimulus';
import { definitionsFromContext } from "@hotwired/stimulus-webpack-helpers"

// JS libraries
import "../scripts/frontend/setup_jquery";
import "../scripts/frontend/setup_translations";
import LazyLoad from 'vanilla-lazyload';

import 'bootstrap';

// jquery ui widgets
import "jquery-ui/ui/widgets/datepicker";

// modules
import Player from "../scripts/frontend/player";
import { Fancybox } from "@fancyapps/ui";

// vendored files
import "../scripts/vendor/jquery.form";
import "../scripts/vendor/jquery.jplayer.min";

window.Player = Player;

const application = Application.start();
import FacebookShareController from '../scripts/controllers/facebook_share_controller';
import BookingController from '../scripts/controllers/booking_controller';
import GalleryController from '../scripts/controllers/gallery_controller';
import PhoneNumberInputController from '../scripts/controllers/phone_number_input_controller';
import SingularPhoneNumberInputController from '../scripts/controllers/singular_phone_number_input_controller';
import ToggleContentController from '../scripts/controllers/toggle_content_controller';

application.register("facebook-share", FacebookShareController);
application.register("booking", BookingController);
application.register("gallery", GalleryController);
application.register("phone-number-input", PhoneNumberInputController);
application.register("singular-phone-number-input", SingularPhoneNumberInputController);
application.register("toggle-content", ToggleContentController);

$(function() {
  // activate lazyloading
  new LazyLoad();
});
